/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

'use strict';

goog.provide('Blockly.Tinusaur.tinusaur');

goog.require('Blockly.Tinusaur');

// ==== NEW IDES: LIBRARIES ===================================================
// Currently not used anywhere
var libraries_available = {
	tinyavrlib_adcxlib: {
		name: "tinyavrlib-adcxlib",
		description: "",
		requires: [],
	},
	tinyavrlib_buzzlib: {
		name: "tinyavrlib-buzzlib",
		description: "",
		requires: [],
	},
	tinyavrlib_soundlib: {
		name: "tinyavrlib-soundlib",
		description: "",
		requires: [],
	},
	tinyavrlib_scheduler: {
		name: "tinyavrlib-scheduler",
		description: "",
		requires: [],
	},
	tinyavrlib_num2str: {
		name: "tinyavrlib-num2str",
		description: "NUM2STR - Functions to handle the conversion of numeric vales to strings.",
		requires: [],
	},
	tinusaur_owowod: {
		name: "tinusaur-owowod",
		description: "",
		requires: [ "tinyavrlib_num2str" ],
	},
}

// ==== BOARDS DEFINITIONS ====================================================

var board_profile = {
    tinusaur_blocks: {
        description: "Tinusaur Board",
        digital: [["PB0", "PB0"], ["PB1", "PB1"],  ["PB2", "PB2"],  ["PB3", "PB3"],  ["PB4", "PB4"]],
        analog: [["A0", "A0"], ["A1", "A1"]],
        serial: 9600,
        tinusaur_common: {
			comsio: "PB3", // COMTiny/COMS I/O PIN
        },
        board_main: {
            button: "PB3",
			comout: "PB3",
			cominp: "PB3",
        },
        shield_ledx1: {
            led: "PB0",
			leds: [["PB0", "PB0"], ["PB1", "PB1"],  ["PB2", "PB2"],  ["PB3", "PB3"],  ["PB4", "PB4"]],
        },
        shield_ledx2: {
            led_red: "PB0",
            led_green: "PB1",
            servo: "PB2",
			extout: "PB4",
			extinp: "PB4",
        },
        shield_ledx3: {
            led_red: "PB0",
            led_yellow: "PB1",
            led_green: "PB2",
            servo: "PB4",
        },
        shield_edux3io: {
            led: "PB0",
            servo: "PB1",
            photores: "PB2",
            buzzer: "PB4",
        },
        shield_magx3io: {
            led: "PB0",
            servo: "PB1",
            hallsens: "PB2",
            buzzer: "PB4",
        },
        shield_motox4: {
            motor1: "PB0",
            motor2: "PB1",
            irleds: "PB3",
            irsens: [["IR Sens 1", "ADC1"], ["IR Sens 2", "ADC2"]],
        },
        shield_edux4io: {
            led: "PB0",
            button: "PB1",
            photores: "PB2",
            buzzer: "PB4",
        },
        board_kidsmicro: {
            led_red: "PB0",
            led_green: "PB1",
            photores: "PB2",
            button: "PB3",
            buzzer: "PB4",
        },
        board_kidsmini: {
            led_white: "PB0",
            servo: "PB1",
            photores: "PB2",
            button: "PB3",
            buzzer: "PB4",
        },
        tinyavrlib_adcxlib: {
            adcx: [["ADC0 (PB5)", "0b0000"], ["ADC1 (PB2)", "0b0001"], ["ADC2 (PB4)", "0b0010"], ["ADC3 (PB3)", "0b0011"], ["ADC4 (Int.Temp.)", "0b1111"], ],
            temp: "0b1111",
        },
        libraries: [],
    },
    generic_blocks: {
        description: "Generic Board ATtiny85",
        digital: [["PB0", "PB0"], ["PB1", "PB1"],  ["PB2", "PB2"],  ["PB3", "PB3"],  ["PB4", "PB4"]],
    },
};

// Set the default board profile a Tinusaur board
board_profile["default"] = board_profile["tinusaur_blocks"];

Blockly.Tinusaur.tinusaur_delay_ms = function () {
    var delay_time_ms = this.getFieldValue('DELAY_TIME_MS');
    Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
    var code = '_delay_ms(' + delay_time_ms + ');\t// Delay of ' + delay_time_ms + ' milliseconds.\n';
    return code;
};

Blockly.Tinusaur.tinusaur_delay_us = function () {
    var delay_time_us = this.getFieldValue('DELAY_TIME_US');
    Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
    var code = '_delay_us(' + delay_time_us + ');\t// Delay of ' + delay_time_us + ' milliseconds.\n';
    return code;
};

Blockly.Tinusaur.base_map = function () {
    var value_num = Blockly.Tinusaur.valueToCode(this, 'NUM', Blockly.Tinusaur.ORDER_NONE);
    var value_dmax = Blockly.Tinusaur.valueToCode(this, 'DMAX', Blockly.Tinusaur.ORDER_ATOMIC);
    var code = 'map(' + value_num + ', 0, 1024, 0, ' + value_dmax + ')';
    return [code, Blockly.Tinusaur.ORDER_NONE];
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Tinusaur.tinusaur_buildin_led = function () {
    var dropdown_stat = this.getFieldValue('STAT');
    Blockly.Tinusaur.setups_['setup_output_13'] = 'pinMode(13, OUTPUT);';
    var code = 'digitalWrite(13, ' + dropdown_stat + ');\n'
    return code;
};

Blockly.Tinusaur.tinusaur_output_portb = function () {
    var dropdown_pin = this.getFieldValue('PIN');
    var dropdown_stat = this.getFieldValue('STAT');
    Blockly.Tinusaur.setups_['setup_output_' + dropdown_pin] = 'DDRB  |= (1 << ' + dropdown_pin + ');\t// Set port as output';
    var code = (dropdown_stat == 'HIGH' ? 'PORTB |= (1 << ' + dropdown_pin + ');' : 'PORTB &= ~(1 << ' + dropdown_pin + ');' ) + '\n';
    return code;
};

Blockly.Tinusaur.tinusaur_input_portb = function () {
    var dropdown_pin = this.getFieldValue('PIN');
    Blockly.Tinusaur.setups_['setup_input_portb_' + dropdown_pin] = 'DDRB &= ~(1 << ' + dropdown_pin + ');\t// Set port as input';
	Blockly.Tinusaur.setups_['setup_input_portb_' + dropdown_pin + '_pullup'] = 'PORTB |= (1 << ' + dropdown_pin + ');\t// Set port pull-up resistor';
    var code = '(PINB & (1 << ' + dropdown_pin + '))';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Tinusaur.tinusaur_analog_write = function () {
    var dropdown_pin = this.getFieldValue('PIN');
    //var dropdown_stat = this.getFieldValue('STAT');
    var value_num = Blockly.Tinusaur.valueToCode(this, 'NUM', Blockly.Tinusaur.ORDER_ATOMIC);
    //Blockly.Tinusaur.setups_['setup_output' + dropdown_pin] = 'pinMode('+dropdown_pin+', OUTPUT);';
    var code = 'analogWrite(' + dropdown_pin + ', ' + value_num + ');\n';
    return code;
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Tinusaur.tinusaur_analog_read = function () {
    var dropdown_pin = this.getFieldValue('PIN');
    //Blockly.Tinusaur.setups_['setup_input_' + dropdown_pin] = 'pinMode('+dropdown_pin+', INPUT);';
    var code = 'analogRead(' + dropdown_pin + ')';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Tinusaur.tinusaur_tone = function () {
    var dropdown_pin = this.getFieldValue("PIN");
    var value_num = Blockly.Tinusaur.valueToCode(this, "NUM", Blockly.Tinusaur.ORDER_ATOMIC);
    Blockly.Tinusaur.setups_['setup_output' + dropdown_pin] = 'pinMode(' + dropdown_pin + ', OUTPUT);';
    var code = "tone(" + dropdown_pin + ", " + value_num + ");\n";
    return code;
};

// NOTE: Not properly implemented. DO NOT include it in the toolbox.
Blockly.Tinusaur.tinusaur_notone = function () {
    var dropdown_pin = this.getFieldValue("PIN");
    Blockly.Tinusaur.setups_['setup_output' + dropdown_pin] = 'pinMode(' + dropdown_pin + ', OUTPUT);';
    var code = "noTone(" + dropdown_pin + ");\n";
    return code;
};

Blockly.Tinusaur.tinusaur_highlow = function () {
    // Boolean values HIGH and LOW.
    var code = (this.getFieldValue('BOOL') == 'HIGH') ? 'HIGH' : 'LOW';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

// ==== Common Components ========================================================

Blockly.Tinusaur.tinusaur_common_coms_sig = function () {
    var port = board_profile.default.tinusaur_common.comsio;
	Blockly.Tinusaur.libraries_['tinyavrlib-comtiny'] = 'tinyavrlib-comtiny';
	Blockly.Tinusaur.includes_['tinyavrlib-comtiny'] = '#include "tinyavrlib/comtiny.h"';
    Blockly.Tinusaur.setups_['setup_shield_xxxx_coms_msby'] = 'COMS_MSBY();\t// COMS: Set mode to Stand-by.';
    var code = 'COMS_SIG();\t// COMS: Send a signal out.\n';
    return code;
};

Blockly.Tinusaur.tinusaur_common_coms_chk = function () {
    var port = board_profile.default.tinusaur_common.comsio;
	Blockly.Tinusaur.libraries_['tinyavrlib-comtiny'] = 'tinyavrlib-comtiny';
	Blockly.Tinusaur.includes_['tinyavrlib-comtiny'] = '#include "tinyavrlib/comtiny.h"';
    Blockly.Tinusaur.setups_['setup_shield_xxxx_coms_msby'] = 'COMS_MSBY();\t// COMS: Set mode to Stand-by.';
    var code = 'COMS_CHK()';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.tinusaur_common_coms_lsn = function () {
    var port = board_profile.default.tinusaur_common.comsio;
	Blockly.Tinusaur.libraries_['tinyavrlib-comtiny'] = 'tinyavrlib-comtiny';
	Blockly.Tinusaur.includes_['tinyavrlib-comtiny'] = '#include "tinyavrlib/comtiny.h"';
    Blockly.Tinusaur.setups_['setup_shield_xxxx_coms_msby'] = 'COMS_MSBY();\t// COMS: Set mode to Stand-by.';
    var code = 'COMS_LSN();\t// COMS:  Listen for input signal. NOTE: This is a blocking operation.\n';
    return code;
};

Blockly.Tinusaur.tinusaur_common_coms_sby = function () {
    var port = board_profile.default.tinusaur_common.comsio;
	Blockly.Tinusaur.libraries_['tinyavrlib-comtiny'] = 'tinyavrlib-comtiny';
	Blockly.Tinusaur.includes_['tinyavrlib-comtiny'] = '#include "tinyavrlib/comtiny.h"';
    var code = 'COMS_MSBY();\t// COMS: Set mode to Stand-by.';
    return code;
};

// ==== Tinusaur Board ========================================================

Blockly.Tinusaur.tinusaur_board_main_button = function () {
    var port = board_profile.default.board_main.button;
    Blockly.Tinusaur.setups_['setup_board_main_button_port'] = 'DDRB &= ~(1 << ' + port + ');\t// Button (Built-in): Set port as input';
	Blockly.Tinusaur.setups_['setup_board_main_button_pullup'] = 'PORTB |= (1 << ' + port + ');\t// Button (Built-in): Set port pull-up resistor';
    var code = '!(PINB & (1 << ' + port + '))';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.tinusaur_board_main_comout = function () {
    var port = board_profile.default.board_main.comout;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_board_main_comout'] = 'DDRB |= (1 << ' + port + ');\t\t// ComOut: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_board_main_cominp = function () {
    var port = board_profile.default.board_main.cominp;
    Blockly.Tinusaur.setups_['setup_board_main_cominp_port'] = 'DDRB &= ~(1 << ' + port + ');\t// ComInp: Set port as input';
	Blockly.Tinusaur.setups_['setup_board_main_cominp_pullup'] = 'PORTB |= (1 << ' + port + ');\t// ComInp: Set port pull-up resistor';
    var code = '(PINB & (1 << ' + port + '))';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

// ==== Tinusaur Shields ======================================================

// ---- Shield LEDx1 ----------------------------------------------------------

Blockly.Tinusaur.tinusaur_shield_ledx1_pb0 = function () {
    var port = board_profile.default.shield_ledx1.led;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_ledx1_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_ledx1 = function () {
    var port = this.getFieldValue('PIN');
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_ledx1_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
}

// ---- Shield LEDx2 ----------------------------------------------------------

Blockly.Tinusaur.tinusaur_shield_ledx2_led_red = function () {
    var port = board_profile.default.shield_ledx2.led_red;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_ledx2_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_ledx2_led_green = function () {
    var port = board_profile.default.shield_ledx2.led_green;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_ledx2_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_ledx2_extout = function () {
    var port = board_profile.default.shield_ledx2.extout;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_ledx2_extout'] = 'DDRB |= (1 << ' + port + ');\t\t// EXT: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// EXT: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// EXT: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_ledx2_extinp = function () {
    var port = board_profile.default.shield_ledx2.extinp;
    Blockly.Tinusaur.setups_['setup_shield_ledx2_extinp_port'] = 'DDRB &= ~(1 << ' + port + ');\t// EXT: Set port as input';
	Blockly.Tinusaur.setups_['setup_shield_ledx2_extinp_pullup'] = 'PORTB |= (1 << ' + port + ');\t// EXT: Set port pull-up resistor';
    var code = '(PINB & (1 << ' + port + '))';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.tinusaur_shield_ledx2_servo = function () {
	Blockly.Tinusaur.libraries_['servolibtiny'] = 'servolibtiny';
	Blockly.Tinusaur.includes_['servolibtiny-servolibtiny'] = '#include "servolibtiny/servolibtiny.h"';
    Blockly.Tinusaur.declarations_['shield_ledx2_servo'] =
		'void shield_ledx2_servo(int16_t servo_pos, uint8_t pulse_count) {\n' +
		'	if (servo_pos < -100) servo_pos = -100;\t// Check min value.\n' +
		'	if (servo_pos > 350) servo_pos = 350;\t// Check max value.\n' +
		'	servolibtiny_pos(servo_pos, pulse_count);\n' +
		'}\n';
    var servo = board_profile.default.shield_ledx2.servo;
    var servo_pos = Blockly.Tinusaur.valueToCode(this, 'SERVO_POS', 0);
    var servo_repeat = Blockly.Tinusaur.valueToCode(this, 'SERVO_REPEAT', 0);
    Blockly.Tinusaur.setups_['setup_shield_ledx2_servo'] = 'servolibtiny_sel(' + servo + ');	// Select servo and init the port.';
    var code = 'shield_ledx2_servo(' + servo_pos + ', ' + servo_repeat + ');\t// Set the servo in position.\n';
    return code;
};

// ---- Shield LEDx3 ----------------------------------------------------------

Blockly.Tinusaur.tinusaur_shield_ledx3_led_red = function () {
    var port = board_profile.default.shield_ledx3.led_red;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_ledx3_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_ledx3_led_yellow = function () {
    var port = board_profile.default.shield_ledx3.led_yellow;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_ledx3_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_ledx3_led_green = function () {
    var port = board_profile.default.shield_ledx3.led_green;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_ledx3_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_ledx3_servo = function () {
	Blockly.Tinusaur.libraries_['servolibtiny'] = 'servolibtiny';
	Blockly.Tinusaur.includes_['servolibtiny-servolibtiny'] = '#include "servolibtiny/servolibtiny.h"';
    Blockly.Tinusaur.declarations_['shield_ledx3_servo'] =
		'void shield_ledx3_servo(int16_t servo_pos, uint8_t pulse_count) {\n' +
		'	if (servo_pos < -100) servo_pos = -100;\t// Check min value.\n' +
		'	if (servo_pos > 350) servo_pos = 350;\t// Check max value.\n' +
		'	servolibtiny_pos(servo_pos, pulse_count);\n' +
		'}\n';
    var servo = board_profile.default.shield_ledx3.servo;
    var servo_pos = Blockly.Tinusaur.valueToCode(this, 'SERVO_POS', 0);
    var servo_repeat = Blockly.Tinusaur.valueToCode(this, 'SERVO_REPEAT', 0);
    Blockly.Tinusaur.setups_['setup_shield_ledx3_servo'] = 'servolibtiny_sel(' + servo + ');	// Select servo and init the port.';
    var code = 'shield_ledx3_servo(' + servo_pos + ', ' + servo_repeat + ');\t// Set the servo in position.\n';
    return code;
};

// ---- Shield EDUx3IO --------------------------------------------------------

Blockly.Tinusaur.tinusaur_shield_edux3io_led = function () {
    var port = board_profile.default.shield_edux3io.led;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_edux3io_led'] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_edux3io_buzzer = function () {
    var port = board_profile.default.shield_edux3io.buzzer;
    Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
    Blockly.Tinusaur.declarations_['shield_edux3io_buzzer'] =
		'void shield_edux3io_buzzer(int period, int repeats) { // 1 period for 1 repeat = 8 us\n' +
		'	for (int delay = (period >> 1) + 1; repeats > 0; repeats--) {\n' +
		'		PORTB |= (1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'		PORTB &= ~(1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'	}\n' +
		'}\n';
    Blockly.Tinusaur.setups_['setup_shield_edux3io_buzzer'] = 'DDRB |= (1 << ' + port + ');\t\t// Buzzer: Set port as output';
    var period = Blockly.Tinusaur.valueToCode(this, 'PERIOD', 0);
    var repeats = Blockly.Tinusaur.valueToCode(this, 'REPEATS', 0);
    var code = 'shield_edux3io_buzzer(' + period + ', ' + repeats + ');\t// Play a tone.\n';
    return code;
};

Blockly.Tinusaur.tinusaur_shield_edux3io_photores = function () {
	Blockly.Tinusaur.libraries_['tinyavrlib-adcxlib'] = 'tinyavrlib-adcxlib';
	Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
	Blockly.Tinusaur.includes_['tinyavrlib-adcxlib'] = '#include "tinyavrlib/adcxlib.h"';
    Blockly.Tinusaur.declarations_['shield_edux3io_photores'] =
		'int shield_edux3io_photores(void) {\n' +
		'	ADCX_ADCSEL(ADCX_SELECT_MASK_ADC1);\t// Select the ADC input.\n' +
		// '	ADCX_REFSEL(ADCX_REFSEL_MASK_VCC);\t// Set the reference voltage source.\n' + // VCC is the default
		'	_delay_ms(ADCX_CHANGE_DELAY);\t// Small delay is required after change.\n' +
		'	return adcx_read();\n' +
		'}\n';
    Blockly.Tinusaur.setups_['tinyavrlib-adcxlib'] =
		'// Init the ADC and start conversion.\n' +
		'	adcx_init();\n' +
		'	ADCX_START();\n';
    var code = 'shield_edux3io_photores()';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.tinusaur_shield_edux3io_servo = function () {
	Blockly.Tinusaur.libraries_['servolibtiny'] = 'servolibtiny';
	Blockly.Tinusaur.includes_['servolibtiny-servolibtiny'] = '#include "servolibtiny/servolibtiny.h"';
    Blockly.Tinusaur.declarations_['shield_edux3io_servo'] =
		'void shield_edux3io_servo(int16_t servo_pos, uint8_t pulse_count) {\n' +
		'	if (servo_pos < -100) servo_pos = -100;\t// Check min value.\n' +
		'	if (servo_pos > 350) servo_pos = 350;\t// Check max value.\n' +
		'	servolibtiny_pos(servo_pos, pulse_count);\n' +
		'}\n';
    var servo = board_profile.default.shield_edux3io.servo;
    var servo_pos = Blockly.Tinusaur.valueToCode(this, 'SERVO_POS', 0);
    var servo_repeat = Blockly.Tinusaur.valueToCode(this, 'SERVO_REPEAT', 0);
    Blockly.Tinusaur.setups_['setup_shield_edux3io_servo'] = 'servolibtiny_sel(' + servo + ');	// Select servo and init the port.';
    var code = 'shield_edux3io_servo(' + servo_pos + ', ' + servo_repeat + ');\t// Set the servo in position.\n';
    return code;
};

// ---- Shield MAGx3IO -------------------------------------------------------

Blockly.Tinusaur.tinusaur_shield_magx3io_led = function () {
    var port = board_profile.default.shield_magx3io.led;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_magx3io_led'] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_magx3io_buzzer = function () {
    var port = board_profile.default.shield_magx3io.buzzer;
    Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
    Blockly.Tinusaur.declarations_['shield_magx3io_buzzer'] =
		'void shield_magx3io_buzzer(int period, int repeats) { // 1 period for 1 repeat = 8 us\n' +
		'	for (int delay = (period >> 1) + 1; repeats > 0; repeats--) {\n' +
		'		PORTB |= (1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'		PORTB &= ~(1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'	}\n' +
		'}\n';
    Blockly.Tinusaur.setups_['setup_shield_magx3io_buzzer'] = 'DDRB |= (1 << ' + port + ');\t\t// Buzzer: Set port as output';
    var period = Blockly.Tinusaur.valueToCode(this, 'PERIOD', 0);
    var repeats = Blockly.Tinusaur.valueToCode(this, 'REPEATS', 0);
    var code = 'shield_magx3io_buzzer(' + period + ', ' + repeats + ');\t// Play a tone.\n';
    return code;
};

Blockly.Tinusaur.tinusaur_shield_magx3io_servo = function () {
	Blockly.Tinusaur.libraries_['servolibtiny'] = 'servolibtiny';
	Blockly.Tinusaur.includes_['servolibtiny-servolibtiny'] = '#include "servolibtiny/servolibtiny.h"';
    Blockly.Tinusaur.declarations_['shield_magx3io_servo'] =
		'void shield_magx3io_servo(int16_t servo_pos, uint8_t pulse_count) {\n' +
		'	if (servo_pos < -100) servo_pos = -100;\t// Check min value.\n' +
		'	if (servo_pos > 350) servo_pos = 350;\t// Check max value.\n' +
		'	servolibtiny_pos(servo_pos, pulse_count);\n' +
		'}\n';
    var servo = board_profile.default.shield_magx3io.servo;
    var servo_pos = Blockly.Tinusaur.valueToCode(this, 'SERVO_POS', 0);
    var servo_repeat = Blockly.Tinusaur.valueToCode(this, 'SERVO_REPEAT', 0);
    Blockly.Tinusaur.setups_['setup_shield_magx3io_servo'] = 'servolibtiny_sel(' + servo + ');	// Select servo and init the port.';
    var code = 'shield_magx3io_servo(' + servo_pos + ', ' + servo_repeat + ');\t// Set the servo in position.\n';
    return code;
};

Blockly.Tinusaur.tinusaur_shield_magx3io_hallsens = function () {
	Blockly.Tinusaur.libraries_['tinyavrlib-adcxlib'] = 'tinyavrlib-adcxlib';
	Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
	Blockly.Tinusaur.includes_['tinyavrlib-adcxlib'] = '#include "tinyavrlib/adcxlib.h"';
    Blockly.Tinusaur.declarations_['shield_magx3io_hallsens'] =
		'int shield_magx3io_hallsens(void) {\n' +
		'	ADCX_ADCSEL(ADCX_SELECT_MASK_ADC1);\t// Select the ADC input.\n' +
		// '	ADCX_REFSEL(ADCX_REFSEL_MASK_VCC);\t// Set the reference voltage source.\n' + // VCC is the default
		'	_delay_ms(ADCX_CHANGE_DELAY);\t// Small delay is required after change.\n' +
		'	return adcx_read();\n' +
		'}\n';
    Blockly.Tinusaur.setups_['tinyavrlib-adcxlib'] =
		'adcx_init();\t// Init the ADC.\n' +
		'	ADCX_START();\t// Start ADC conversion.\n';
    var code = 'shield_magx3io_hallsens()';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

// ---- Shield MOTOx4 --------------------------------------------------------

Blockly.Tinusaur.tinusaur_shield_motox4_motor1 = function () {
    var port = board_profile.default.shield_motox4.motor1;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_motox4_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// Motor: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// Motor: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// Motor: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_motox4_motor2 = function () {
    var port = board_profile.default.shield_motox4.motor2;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_motox4_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// Motor: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// Motor: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// Motor: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_motox4_servomots = function () {
	Blockly.Tinusaur.libraries_['servowheeltiny'] = 'servowheeltiny';
	Blockly.Tinusaur.includes_['tinyavrlib-scheduler'] = '#include "tinyavrlib/scheduler.h"';
	Blockly.Tinusaur.includes_['servowheeltiny-servowheeltiny'] = '#include "servowheeltiny/servowheeltiny.h"';
    var servomot1_val = Blockly.Tinusaur.valueToCode(this, 'SERVOMOT1_VAL', 0);
    var servomot2_val = Blockly.Tinusaur.valueToCode(this, 'SERVOMOT2_VAL', 0);
    Blockly.Tinusaur.setups_['tinyavrlib-scheduler'] = 
		'scheduler_init();\t\t// TinyAVRLib-Scheduler: Init - the default is 1ms.\n' +
		// '	scheduler_reinit(SCHEDULER_TCCR0B_0008, 123);\t\t// Scheduler: Re-init at 1ms per tick.\n' + // the default is 1ms
		'	scheduler_start();\t\t// Scheduler: Start.';
    Blockly.Tinusaur.setups_['servowheeltiny-servowheeltiny'] = 'servowheel_init();\t\t// ServoWheelTiny: Init';
    Blockly.Tinusaur.declarations_['servowheeltiny'] =
		'void shield_motox4_servomots(int8_t val1, int8_t val2) {\n' +
		'	if (val1 < -36) val1 = -36;\t// Check min value.\n' +
		'	if (val1 > 36) val1 = 36;\t// Check max value.\n' +
		'	if (val2 < -36) val2 = -36;\t// Check min value.\n' +
		'	if (val2 > 36) val2 = 36;\t// Check max value.\n' +
		'	servowheel_motors(val1, val2);\n' +
		'}\n';
    var code = '';
	code += 'shield_motox4_servomots(' + servomot1_val + ', ' + servomot2_val + ');\t// Motors: Set movement\n';
    return code;
};

Blockly.Tinusaur.tinusaur_shield_motox4_irleds = function () {
    var port = board_profile.default.shield_motox4.irleds;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_motox4_' + port] = 'DDRB |= (1 << ' + port + ');\t\t// IR Leds: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// IR LEDs: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// IR LEDs: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_motox4_irsens = function () {
	Blockly.Tinusaur.libraries_['tinyavrlib-adcxlib'] = 'tinyavrlib-adcxlib';
	Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
	Blockly.Tinusaur.includes_['tinyavrlib-adcxlib'] = '#include "tinyavrlib/adcxlib.h"';
    Blockly.Tinusaur.declarations_['shield_motox4_irsens'] =
		'int shield_motox4_irsens(int mask) {\n' +
		'	ADCX_ADCSEL(mask);\t// Select the ADC input.\n' +
		// '	ADCX_REFSEL(ADCX_REFSEL_MASK_VCC);\t// Set the reference voltage source.\n' + // VCC is the default
		'	_delay_ms(ADCX_CHANGE_DELAY);\t// Small delay is required after change.\n' +
		'	return adcx_read();\n' +
		'}\n';
    Blockly.Tinusaur.setups_['tinyavrlib-adcxlib'] =
		'// Init the ADC and start conversion.\n' +
		'	adcx_init();\n' +
		'	ADCX_START();\n';
    var irsens = this.getFieldValue('IRSENS');
    var code;
	if (irsens == 'ADC1') {
		code = 'shield_motox4_irsens(ADCX_SELECT_MASK_ADC1)';
	} else if (irsens == 'ADC2') {
		code = 'shield_motox4_irsens(ADCX_SELECT_MASK_ADC2)';
	} else {
		code = '/* ADCX SELECT ERROR */';
	}
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
}

// ---- Shield OLEDx1 --------------------------------------------------------

Blockly.Tinusaur.tinusaur_shield_oledx1_clear = function () {
	Blockly.Tinusaur.libraries_['blocktinu-oled'] = 'blocktinu-oled';
	Blockly.Tinusaur.includes_['blocktinu-oled'] = '#include "blocktinu_oled/blocktinu_oled.h"';
    Blockly.Tinusaur.setups_['blocktinu-oled'] = 'oled_init();\t\t// OLED Display: Init';
    var code = '';
	code += 'oled_clear();\t// OLED: Clear the screen\n';
    return code;
};

Blockly.Tinusaur.tinusaur_shield_oledx1_fill = function () {
	Blockly.Tinusaur.libraries_['blocktinu-oled'] = 'blocktinu-oled';
	Blockly.Tinusaur.includes_['blocktinu-oled'] = '#include "blocktinu_oled/blocktinu_oled.h"';
    Blockly.Tinusaur.setups_['blocktinu-oled'] = 'oled_init();\t\t// OLED Display: Init';
    var code = '';
	code += 'oled_fill();\t// OLED: Fill the screen"\n';
    return code;
};

Blockly.Tinusaur.tinusaur_shield_oledx1_text = function () {
	Blockly.Tinusaur.libraries_['blocktinu-oled'] = 'blocktinu-oled';
	Blockly.Tinusaur.includes_['blocktinu-oled'] = '#include "blocktinu_oled/blocktinu_oled.h"';
    Blockly.Tinusaur.setups_['blocktinu-oled'] = 'oled_init();\t\t// OLED Display: Init';
    var posx = this.getFieldValue('POSX');
    var posy = this.getFieldValue('POSY');
    var text = this.getFieldValue('TEXT');
    var code = '';
	code += 'oled_string(' + posx + ', ' + posy + ', "' + text + '");\t// OLED: Text\n';
    return code;
};

Blockly.Tinusaur.tinusaur_shield_oledx1_nump = function () {
	Blockly.Tinusaur.libraries_['blocktinu-oled'] = 'blocktinu-oled';
	Blockly.Tinusaur.includes_['blocktinu-oled'] = '#include "blocktinu_oled/blocktinu_oled.h"';
    Blockly.Tinusaur.setups_['blocktinu-oled'] = 'oled_init();\t\t// OLED Display: Init';
    var posx = this.getFieldValue('POSX');
    var posy = this.getFieldValue('POSY');
    var num = Blockly.Tinusaur.valueToCode(this, 'NUM', 0);
    var code = '';
	code += 'oled_nump(' + posx + ', ' + posy + ', ' + num + ');\t// OLED: Number\n';
    return code;
};

// ---- Shield LEDx12 --------------------------------------------------------

Blockly.Tinusaur.tinusaur_shield_ledx12 = function () {
	Blockly.Tinusaur.libraries_['shieldledx12-chrlplxng'] = 'shieldledx12-chrlplxng';
	Blockly.Tinusaur.includes_['shieldledx12-chrlplxng'] = '#include "shield_ledx12/chrlplxng.h"';
    var port = Blockly.Tinusaur.valueToCode(this, 'CHRLPLXNG_LED', 0);
    Blockly.Tinusaur.setups_['setup_shield_ledx12_chrlplxng'] = 'chrlplxng_off();\t\t// LEDx12: Turn all LEDs off';
    var code = '';
	code += 'chrlplxng_led(' + port + ');\t// LEDx12: Turn the LED on\n';
    return code;
};

Blockly.Tinusaur.tinusaur_shield_ledx12_off = function () {
	Blockly.Tinusaur.libraries_['shieldledx12-chrlplxng'] = 'shieldledx12-chrlplxng';
	Blockly.Tinusaur.includes_['shieldledx12-chrlplxng'] = '#include "shield_ledx12/chrlplxng.h"';
    Blockly.Tinusaur.setups_['setup_shield_ledx12_chrlplxng'] = 'chrlplxng_off();\t\t// LEDx12: Turn all LEDs off';
    var code = '';
	code += 'chrlplxng_off();\t// LEDx12: Turn all LEDs off\n';
    return code;
};

// ---- LEGACY: Shield EDUx4IO --------------------------------------------------------

Blockly.Tinusaur.tinusaur_shield_edux4io_led = function () {
    var port = board_profile.default.shield_edux4io.led;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_shield_edux4io_led'] = 'DDRB |= (1 << ' + port + ');\t\t// LED: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_shield_edux4io_buzzer = function () {
    var port = board_profile.default.shield_edux4io.buzzer;
    Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
    Blockly.Tinusaur.declarations_['shield_edux4io_buzzer'] =
		'void shield_edux4io_buzzer(int period, int repeats) { // 1 period for 1 repeat = 8 us\n' +
		'	for (int delay = (period >> 1) + 1; repeats > 0; repeats--) {\n' +
		'		PORTB |= (1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'		PORTB &= ~(1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'	}\n' +
		'}\n';
    Blockly.Tinusaur.setups_['setup_shield_edux4io_buzzer'] = 'DDRB |= (1 << ' + port + ');\t\t// Buzzer: Set port as output';
    var period = Blockly.Tinusaur.valueToCode(this, 'PERIOD', 0);
    var repeats = Blockly.Tinusaur.valueToCode(this, 'REPEATS', 0);
    var code = 'shield_edux4io_buzzer(' + period + ', ' + repeats + ');\t// Play a tone.\n';
    return code;
};

Blockly.Tinusaur.tinusaur_shield_edux4io_button = function () {
    var port = board_profile.default.shield_edux4io.button;
    Blockly.Tinusaur.setups_['setup_shield_edux4io_button_port'] = 'DDRB &= ~(1 << ' + port + ');\t// Button: Set port as input.';
	Blockly.Tinusaur.setups_['setup_shield_edux4io_button_pullup'] = 'PORTB |= (1 << ' + port + ');\t// Button: Set port pull-up resistor.';
    var code = '!(PINB & (1 << ' + port + '))';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.tinusaur_shield_edux4io_photores = function () {
	Blockly.Tinusaur.libraries_['tinyavrlib-adcxlib'] = 'tinyavrlib-adcxlib';
	Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
	Blockly.Tinusaur.includes_['tinyavrlib-adcxlib'] = '#include "tinyavrlib/adcxlib.h"';
    Blockly.Tinusaur.declarations_['shield_edux4io_photores'] =
		'int shield_edux4io_photores(void) {\n' +
		'	ADCX_ADCSEL(ADCX_SELECT_MASK_ADC1);\t// Select the ADC input.\n' +
		// '	ADCX_REFSEL(ADCX_REFSEL_MASK_VCC);\t// Set the reference voltage source.\n' + // VCC is the default
		'	_delay_ms(ADCX_CHANGE_DELAY);\t// Small delay is required after change.\n' +
		'	return adcx_read();\n' +
		'}\n';
    Blockly.Tinusaur.setups_['tinyavrlib-adcxlib'] =
		'// Init the ADC and start conversion.\n' +
		'	adcx_init();\n' +
		'	ADCX_START();\n';
    var code = 'shield_edux4io_photores()';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

// ---- Board KidsMicro --------------------------------------------------------

Blockly.Tinusaur.tinusaur_board_kidsmicro_led_red = function () {
    var port = board_profile.default.board_kidsmicro.led_red;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_board_kidsmicro_led_red'] = 'DDRB |= (1 << ' + port + ');\t\t// LED Red: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED Red: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED Red: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_board_kidsmicro_led_green = function () {
    var port = board_profile.default.board_kidsmicro.led_green;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_board_kidsmicro_led_green'] = 'DDRB |= (1 << ' + port + ');\t\t// LED Green: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED Green: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED Green: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_board_kidsmicro_button = function () {
    var port = board_profile.default.board_kidsmicro.button;
    Blockly.Tinusaur.setups_['setup_board_kidsmicro_button_port'] = 'DDRB &= ~(1 << ' + port + ');\t// Button: Set port as input';
	Blockly.Tinusaur.setups_['setup_board_kidsmicro_button_pullup'] = 'PORTB |= (1 << ' + port + ');\t// Button: Set port pull-up resistor';
    var code = '!(PINB & (1 << ' + port + '))';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.tinusaur_board_kidsmicro_buzzer = function () {
    var port = board_profile.default.board_kidsmicro.buzzer;
    Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
    Blockly.Tinusaur.declarations_['board_kidsmicro_buzzer'] =
		'void board_kidsmicro_buzzer(int period, int repeats) { // 1 period for 1 repeat = 8 us\n' +
		'	for (int delay = (period >> 1) + 1; repeats > 0; repeats--) {\n' +
		'		PORTB |= (1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'		PORTB &= ~(1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'	}\n' +
		'}\n';
    Blockly.Tinusaur.setups_['setup_board_kidsmicro_buzzer'] = 'DDRB |= (1 << ' + port + ');\t\t// Buzzer: Set port as output';
    var period = Blockly.Tinusaur.valueToCode(this, 'PERIOD', 0);
    var repeats = Blockly.Tinusaur.valueToCode(this, 'REPEATS', 0);
    var code = 'board_kidsmicro_buzzer(' + period + ', ' + repeats + ');\t// Play a tone.\n';
    return code;
};

Blockly.Tinusaur.tinusaur_board_kidsmicro_photores = function () {
	Blockly.Tinusaur.libraries_['tinyavrlib-adcxlib'] = 'tinyavrlib-adcxlib';
	Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
	Blockly.Tinusaur.includes_['tinyavrlib-adcxlib'] = '#include "tinyavrlib/adcxlib.h"';
    Blockly.Tinusaur.declarations_['board_kidsmicro_photores'] =
		'int board_kidsmicro_photores(void) {\n' +
		'	ADCX_ADCSEL(ADCX_SELECT_MASK_ADC1);\t// Select the ADC input.\n' +
		// '	ADCX_REFSEL(ADCX_REFSEL_MASK_VCC);\t// Set the reference voltage source.\n' + // VCC is the default
		'	_delay_ms(ADCX_CHANGE_DELAY);\t// Small delay is required after change.\n' +
		'	return adcx_read();\n' +
		'}\n';
    Blockly.Tinusaur.setups_['tinyavrlib-adcxlib'] =
		'// Init the ADC and start conversion.\n' +
		'	adcx_init();\n' +
		'	ADCX_START();\n';
    var code = 'board_kidsmicro_photores()';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

// ---- Board KidsMini --------------------------------------------------------

Blockly.Tinusaur.tinusaur_board_kidsmini_led_white = function () {
    var port = board_profile.default.board_kidsmini.led_white;
    var state_high = (this.getFieldValue('STAT') == 'HIGH') ? true : false;
    Blockly.Tinusaur.setups_['setup_board_kidsmini_led_white'] = 'DDRB |= (1 << ' + port + ');\t\t// LED Red: Set port as output';
    var code = '';
    if (state_high) {
        code += 'PORTB |= (1 << ' + port + ');\t// LED Red: Set port to high\n';
    } else {
        code += 'PORTB &= ~(1 << ' + port + ');\t// LED Red: Set port to low\n';
    }
    return code;
};

Blockly.Tinusaur.tinusaur_board_kidsmini_button = function () {
    var port = board_profile.default.board_kidsmini.button;
    Blockly.Tinusaur.setups_['setup_board_kidsmini_button_port'] = 'DDRB &= ~(1 << ' + port + ');\t// Button: Set port as input';
	Blockly.Tinusaur.setups_['setup_board_kidsmini_button_pullup'] = 'PORTB |= (1 << ' + port + ');\t// Button: Set port pull-up resistor';
    var code = '!(PINB & (1 << ' + port + '))';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.tinusaur_board_kidsmini_buzzer = function () {
    var port = board_profile.default.board_kidsmini.buzzer;
    Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
    Blockly.Tinusaur.declarations_['board_kidsmini_buzzer'] =
		'void board_kidsmini_buzzer(int period, int repeats) { // 1 period for 1 repeat = 8 us\n' +
		'	for (int delay = (period >> 1) + 1; repeats > 0; repeats--) {\n' +
		'		PORTB |= (1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'		PORTB &= ~(1 << PB4);\n' +
		'		_delay_loop_2(delay);\n' +
		'	}\n' +
		'}\n';
    Blockly.Tinusaur.setups_['setup_board_kidsmini_buzzer'] = 'DDRB |= (1 << ' + port + ');\t\t// Buzzer: Set port as output';
    var period = Blockly.Tinusaur.valueToCode(this, 'PERIOD', 0);
    var repeats = Blockly.Tinusaur.valueToCode(this, 'REPEATS', 0);
    var code = 'board_kidsmini_buzzer(' + period + ', ' + repeats + ');\t// Play a tone.\n';
    return code;
};

Blockly.Tinusaur.tinusaur_board_kidsmini_photores = function () {
	Blockly.Tinusaur.libraries_['tinyavrlib-adcxlib'] = 'tinyavrlib-adcxlib';
	Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
	Blockly.Tinusaur.includes_['tinyavrlib-adcxlib'] = '#include "tinyavrlib/adcxlib.h"';
    Blockly.Tinusaur.declarations_['board_kidsmini_photores'] =
		'int board_kidsmini_photores(void) {\n' +
		'	ADCX_ADCSEL(ADCX_SELECT_MASK_ADC1);\t// Select the ADC input.\n' +
		// '	ADCX_REFSEL(ADCX_REFSEL_MASK_VCC);\t// Set the reference voltage source.\n' + // VCC is the default
		'	_delay_ms(ADCX_CHANGE_DELAY);\t// Small delay is required after change.\n' +
		'	return adcx_read();\n' +
		'}\n';
    Blockly.Tinusaur.setups_['tinyavrlib-adcxlib'] =
		'// Init the ADC and start conversion.\n' +
		'	adcx_init();\n' +
		'	ADCX_START();\n';
    var code = 'board_kidsmini_photores()';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.tinusaur_board_kidsmini_servo = function () {
	Blockly.Tinusaur.libraries_['servolibtiny'] = 'servolibtiny';
	Blockly.Tinusaur.includes_['servolibtiny-servolibtiny'] = '#include "servolibtiny/servolibtiny.h"';
    Blockly.Tinusaur.declarations_['board_kidsmini_servo'] =
		'void board_kidsmini_servo(int16_t servo_pos, uint8_t pulse_count) {\n' +
		'	if (servo_pos < -100) servo_pos = -100;\t// Check min value.\n' +
		'	if (servo_pos > 350) servo_pos = 350;\t// Check max value.\n' +
		'	servolibtiny_pos(servo_pos, pulse_count);\n' +
		'}\n';
    var servo = board_profile.default.board_kidsmini.servo;
    var servo_pos = Blockly.Tinusaur.valueToCode(this, 'SERVO_POS', 0);
    var servo_repeat = Blockly.Tinusaur.valueToCode(this, 'SERVO_REPEAT', 0);
    Blockly.Tinusaur.setups_['setup_board_kidsmini_servo'] = 'servolibtiny_sel(' + servo + ');	// Select servo and init the port.';
    var code = 'board_kidsmini_servo(' + servo_pos + ', ' + servo_repeat + ');\t// Set the servo in position.\n';
    return code;
};

// ---- Library: TinyAVRLib ---------------------------------------------------

Blockly.Tinusaur.library_tinyavrlib_adcx_read = function () {
    var adcx = this.getFieldValue('ADCX');
	Blockly.Tinusaur.libraries_['tinyavrlib-adcxlib'] = 'tinyavrlib-adcxlib';
	Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
	Blockly.Tinusaur.includes_['tinyavrlib-adcxlib'] = '#include "tinyavrlib/adcxlib.h"';
    Blockly.Tinusaur.declarations_['tinyavrlib_adcx_read'] =
		'int tinyavrlib_adcx_read(int adcx) {\n' +
		'	ADCX_ADCSEL(adcx);\t// Select the ADC input.\n' +
		// '	ADCX_REFSEL(ADCX_REFSEL_MASK_VCC);\t// Set the reference voltage source.\n' + // VCC is the default
		'	_delay_ms(ADCX_CHANGE_DELAY);\t// Small delay is required after change.\n' +
		'	return adcx_read();\n' +
		'}\n';
    Blockly.Tinusaur.setups_['tinyavrlib-adcxlib'] =
		'// Init the ADC and start conversion.\n' +
		'	adcx_init();\n' +
		'	ADCX_START();\n';
    var code = 'tinyavrlib_adcx_read(' + adcx + ')';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.library_tinyavrlib_adcx_temp = function () {
	Blockly.Tinusaur.libraries_['tinyavrlib-adcxlib'] = 'tinyavrlib-adcxlib';
	Blockly.Tinusaur.includes_['avrlibc-util-delay'] = '#include <util/delay.h>';
	Blockly.Tinusaur.includes_['tinyavrlib-adcxlib'] = '#include "tinyavrlib/adcxlib.h"';
    Blockly.Tinusaur.declarations_['tinyavrlib_adcx_temp'] =
		'int tinyavrlib_adcx_temp(void) {\n' +
		'	ADCX_ADCSEL(ADCX_SELECT_MASK_ADC4);\t// Select the ADC input.\n' +
		'	ADCX_REFSEL(ADCX_REFSEL_MASK_INT11V);\t// Set the reference voltage source.\n' +
		'	_delay_ms(ADCX_CHANGE_DELAY);\t// Small delay is required after change.\n' +
		'	uint16_t temp = adcx_read();\t// Measure temperature.\n' +
		'	ADCX_REFSEL(ADCX_REFSEL_MASK_VCC);\t// Set the reference voltage source back to the default VCC.\n' +
		'	_delay_ms(ADCX_CHANGE_DELAY);\t// Small delay is required after change.\n' +
		'	return temp;\n' +
		'}\n';
    Blockly.Tinusaur.setups_['tinyavrlib-adcxlib'] =
		'// Init the ADC and start conversion.\n' +
		'	adcx_init();\n' +
		'	ADCX_START();\n';
    var code = 'tinyavrlib_adcx_temp()';
    return [code, Blockly.Tinusaur.ORDER_ATOMIC];
};

Blockly.Tinusaur.library_tinyavrlib_scheduler = function() {
	Blockly.Tinusaur.libraries_['tinyavrlib-scheduler'] = 'tinyavrlib-scheduler';
	Blockly.Tinusaur.includes_['tinyavrlib-scheduler'] = '#include "tinyavrlib/scheduler.h"';
	var branch = Blockly.Tinusaur.statementToCode(this, 'DO');
    Blockly.Tinusaur.declarations_['tinyavrlib_scheduler_usertask'] =
		'uint16_t tinyavrlib_scheduler_counter;\t// Counter.\n' +
		'uint16_t tinyavrlib_scheduler_countdown;\t// Countdown.\n' +
		'void tinyavrlib_scheduler_usertask(void) {\t// User task.\n' +
		'	if (tinyavrlib_scheduler_countdown == 0) {\n' + 
		'\t' + branch.replace(/\n/g, '\n\t') + '\n' +	// Indent each line of the code
		'		tinyavrlib_scheduler_countdown = tinyavrlib_scheduler_counter;\n' + 
		'	} else {\n' + 
		'		tinyavrlib_scheduler_countdown--;\n' + 
		'	}\n' + 
		'}\n';
    Blockly.Tinusaur.setups_['tinyavrlib-scheduler'] = 
		'scheduler_init();\t\t// TinyAVRLib-Scheduler: Init - the default is 1ms.\n' +
		// '	scheduler_reinit(SCHEDULER_TCCR0B_0008, 125);\t\t// Scheduler: Re-init at 1ms per tick.\n' + // the default is 1ms.
		'	scheduler_start();\t\t// Scheduler: Start.';
    var count = this.getFieldValue('COUNT');
    Blockly.Tinusaur.setups_['tinyavrlib-scheduler-usertask'] = 
		'tinyavrlib_scheduler_countdown = tinyavrlib_scheduler_counter = ' + count + ';\t// Init counter and countdown at ' + count + '.\n' +
		'	scheduler_usertask(tinyavrlib_scheduler_usertask, 9);\t// Schedule the user task with count=10 (each 10ms).';
	var code = '';
	return code;
};

// ---- Other Blocks ----------------------------------------------------------

Blockly.Tinusaur.sourcecode_comment = function() {
    var comment = this.getFieldValue('COMMENT');
	var code = '// ' + comment + '\n';
	return code;
};

Blockly.Tinusaur.sourcecode_comment2 = function() {
    var comment = this.getFieldValue('COMMENT');
	var code = '// ' + comment + '\n';
	return code;
};

Blockly.Tinusaur.sourcecode_testing = function() {
    var testing = this.getFieldValue('TESTING');
	Blockly.Tinusaur.libraries_['blocktinu-testing'] = 'blocktinu-testing';
	Blockly.Tinusaur.includes_['blocktinu-testing'] = '#include "testing/testing.h"';
	Blockly.Tinusaur.definitions_['sourcecode_testing'] = '#define TESTING "Testing-' + testing + '"';
	Blockly.Tinusaur.variables_['sourcecode_testing'] = 'int testingVariable;\t// Declare testing variable.';
	Blockly.Tinusaur.declarations_['sourcecode_testing'] = 'void testingFunction(void) {\t// Declare testing function\n  testingVariable = blocktinu_testing_function(); /*TESTING*/\n}\n';
	Blockly.Tinusaur.setups_['sourcecode_testing'] = 'testingVariable = ' + testing + ';\t// Init testing variable.';
	var code = 'testingVariable++;\t// TESTING: ' + testing + '.\n';
	return code;
};

// ---- Advanced Blocks ----------------------------------------------------------

Blockly.Tinusaur.advanced_nop = function() {
	var code = 'asm ("nop");\t// NOP = No-Operation machine code instruction\n';
	return code;
};
